<template src="./print.html">    
</template>
<style scoped src="./print.css">
</style>

<script>
export default {
    data () {
        return {
            transaction : null
        }
    },
    methods : {
        printTransaction (data) {
            this.transaction = {
                userEmail : 'shanugamss@gmail.com',
                fullName : 'shanmugam ',
                address1 : 'address1',
                address2 : 'address2',
                dateTime : '826893-232--23',
                exchangeRate : '2.2',
                referenceNumber : 'oi3uo4u',
                sendAmount : '2',
                serviceCharge : '3',
                recipientName : 'tain di',
                reciveAmount : '4'
            }
            // await this.$htmlToPaper('printMe')
        }
    }
}
</script>